import React, { useState } from 'react';
import logo from '../assets/fpimages/logo.svg'
import hamburger from '../assets/fpimages/bars-solid.svg'
import { Link } from 'react-router-dom';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);  // Track menu state

  const myFunction = () => {
    setMenuOpen(!menuOpen);  // Toggle the menu
  };

  return (
    <header>
      <div className="container">
        <div className="navbar">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <button onClick={myFunction} className="hamburger">
            <img src={hamburger} alt="Menu" />
          </button>
          <div className={`navbar-nav ${menuOpen ? 'menu-open' : ''}`} id="nav-menu">
            <ul className="nav-items">
              <li><Link to ="/pricing" className="nav-link">Pricing</Link></li>
              <li><Link to ="/about" className="nav-link">About us</Link></li>
              <li><Link to ="/contact" className="nav-link">Contact</Link></li>
            </ul>
            <a href="https://market.sharepart.dk" className="btn-success">Log in</a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
