import React from 'react';
import '../styles/AboutPage.css';
import Header from '../components/Header';
import Team from '../components/Team';


function AboutPage() {
 

  return (
    <div>
      <Header />
    
      <div className="about-container">
        {/* Mission Section */}
      <section className="mission-section">
        <div className="section-content">
          <h2>Our Mission</h2>
          <p>
            We're dedicated to transforming the digital landscape by creating
            innovative solutions that empower businesses and individuals alike.
            Through cutting-edge technology and exceptional service, we strive to
            make a lasting impact in the tech industry.
          </p>
        </div>
      </section>

      <Team />

      {/* Values Section */}
      <section className="values-section">
        <h2>Our Values</h2>
        <div className="values-grid">
          <div className="value-card">
            <h3>Innovation</h3>
            <p>Constantly pushing boundaries and exploring new possibilities</p>
          </div>
          <div className="value-card">
            <h3>Excellence</h3>
            <p>Committed to delivering the highest quality in everything we do</p>
          </div>
          <div className="value-card">
            <h3>Integrity</h3>
            <p>Building trust through transparency and honest relationships</p>
          </div>
          <div className="value-card">
            <h3>Collaboration</h3>
            <p>Working together to achieve extraordinary results</p>
          </div>
        </div>
      </section>

     

      {/* Story Section */}
      <section className="story-section">
        <div className="section-content">
          <h2>Our Story</h2>
          <p>
            Founded in 2020, we began with a simple vision: to make technology
            more accessible and user-friendly for everyone. What started as a
            small team of passionate developers has grown into a dynamic
            company serving clients worldwide.
          </p>
          <p>
            Today, we continue to grow and innovate, always staying true to
            our core mission of delivering excellence in every project we
            undertake.
          </p>
        </div>
      </section>
    </div>
    </div>
  );
}

export default AboutPage; 