import React from 'react';
import '../styles/PricingPage.css';
import Header from '../components/Header'; 

import { Link } from 'react-router-dom'; 

function PricingPage() {
  return (
    <div>
      <Header />
      <div className="pricing-container">
        <div className="pricing-header">
        <h1>Simple, Transparent Pricing</h1>
        <p>Choose the plan that's right for you</p>
      </div>

      <div className="pricing-grid">
        {/* Basic Plan */}
        <div className="pricing-card">
          <h2>Basic</h2>
          <p className="plan-description">Perfect for getting started</p>
          <div className="price">
            <span className="period">Price on request</span>
          </div>
          <ul className="features">
            <li>Form partnerships</li>
            <li>Send request to partnerships</li>
            <li>Manage 1500 spare parts</li>
          </ul>
          <Link to="/contact">
            <button className="cta-button">Contact us</button>
          </Link>
        </div>

        {/* Pro Plan */}
        <div className="pricing-card popular">
          <div className="popular-badge">Most Popular</div>
          <h2>Pro</h2>
          <p className="plan-description">Most popular choice</p>
          <div className="price">
            <span className="period">Price on request</span>
          </div>
          <ul className="features">
            <li>Everything in Basic</li>
            <li>Send requests to nearby companies</li>
            <li>Exclude competitiors</li>
            <li>Manage 5000 spare parts</li>
          </ul>
          <Link to="/contact">
            <button className="cta-button">Contact us</button>
          </Link>
        </div>

        {/* Enterprise Plan */}
        <div className="pricing-card">
          <h2>Enterprise</h2>
          <p className="plan-description">For larger organizations</p>
          <div className="price">
            <span className="period">Price on request</span>
          </div>
          <ul className="features">
            <li>Everything in Pro</li>
            <li>Send requests to all companies</li>
            <li>Manage unlimited spare parts</li>
            <li>Manage maintenance schedules including work orders</li>
          </ul>
          <Link to="/contact">
            <button className="cta-button">Contact us</button>
          </Link>
        </div>
      </div>
      </div>
    </div>
  );
}

export default PricingPage;
