import React from 'react';
import cam from '../assets/fpimages/cam.png';

function Question() {
  return (
    <section className="quest-sec">
      <div className="quest-container">
        <div className="quest-box">
          <img src={cam} alt="Camshaft" className="quest-image" />
          <div className="quest-text-box">
            <span className="quest-tag-line">Less downtime</span>
            <h1 className="quest-title">Do you want to secure less downtime?</h1>
            <p className="quest-description">
              We offer a revolutionary solution for spare parts management, uniquely designed to connect companies in close proximity.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Question;
