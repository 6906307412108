import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/ContactPage.css';
import Header from '../components/Header';

function ContactPage() {
  const [status, setStatus] = useState('');
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setStatus('sending');

    const templateParams = {
      to_name: 'SharePart',
      from_name: e.target.from_name.value, 
      user_email: e.target.user_email.value,
      user_phone: e.target.user_phone.value,
      message: e.target.message.value
    };

    emailjs.send(
      'service_1visyjv',
      'template_4hqb9kz',
      templateParams,
      'obCDr6LtvU4WHqzi3'
    )
    .then((response) => {
      setStatus('success');
      e.target.reset(); // Clear the form
      console.log('SUCCESS!', response.status, response.text);
    }, (err) => {
      setStatus('error');
      console.log('FAILED...', err);
    });
  };

  return (
    <div>
      <Header />
      <div className="contact-page">
        <div className="contact-header">
          <h1>Get in Touch</h1>
          <p>Have questions? We'd love to hear from you.</p>
        </div>

        <div className="contact-content">
          <div className="contact-info">
            <div className="info-item">
              <h3>Email</h3>
              <p>mail@sharepart.dk</p>
            </div>
            <div className="info-item">
              <h3>Phone</h3>
              <p>+45 3152 5240</p>
            </div>
            <div className="info-item">
              <h3>Address</h3>
              <p>Forskerparken Videnbyen</p>
              <p>Cortex park 26</p>
              <p>5230 Odense M</p>
              <p>Denmark</p>
            </div>
            <div className="info-item">
              <h3>Hours</h3>
              <p>Monday - Friday: 7.30 - 17.00</p>
              <p>Saturday - Sunday: Closed</p>
            </div>
          </div>

          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="from_name" required />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="user_email" required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone (optional) </label>
                <input type="tel" id="phone" name="user_phone" />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea id="message" name="message" rows="5" required></textarea>
              </div>

              <button 
                type="submit" 
                className="submit-btn"
                disabled={status === 'sending'}
              >
                {status === 'sending' ? 'Sending...' : 'Send Message'}
              </button>

              {status === 'success' && (
                <div className="success-message">
                  Thank you! Your message has been sent successfully.
                </div>
              )}
              {status === 'error' && (
                <div className="error-message">
                  Sorry, there was an error sending your message. Please try again.
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage; 